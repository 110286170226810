import ApexCharts, { ApexOptions } from "apexcharts";
import { FC, useEffect, useRef } from "react"
import { getCSS, getCSSVariableValue } from "../../../_metronic/assets/ts/_utils";
import RatingDistributionItem from "./RatingDistributionItem";

type Props = {
    published: number,
    total: number
}

const TotalReviews: FC<Props> = ({published, total}) => {

    return (

        <div className='card h-200px'>
            <div className='card-header border-0 pt-9'>
                <div className='card-title m-0'>
                    <span className='card-label fw-bolder fs-3 mb-1'>Avaliações publicadas</span>
                </div>
            </div>
            <div className='card-body p-9 pt-1'>
                <div className="fs-3hx fw-bolder text-center">{published}</div>
                <div className="bg-secondary h-8px rounded">
                    <div
                        className={`bg-primary rounded h-8px`}
                        role='progressbar'
                        style={{ width: `${published/total*100}%` }}
                    ></div>
                </div>
                <div className="fs-7 text-muted text-end mt-3">de {total} coletadas</div>
            </div>
        </div>
    )
}

export default TotalReviews
