/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { LayoutSplashScreen } from '../../_metronic/layout/core';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

type Props = {
    children: any
  }
  

const AxiosInterceptor: React.FC<Props> = ({ children }) => {

    const { getAccessTokenSilently, user, isLoading } = useAuth0();
    const [justLoaded, setJustLoaded] = useState(true);

    useEffect(() => {

        console.log('changed', isLoading, user);

        var interceptor = axios.interceptors.request.use(
            async (config: any) => {

                const token = await getAccessTokenSilently({
                    audience: `https://reviews-api.konfidency.com.br`,
                    scope: "read:current_user app_metadata",
                });

                if (user) {
                    const metadata = user['https://reviews-api.konfidency.com.br/app_metadata'];

                    const storageCustomer = window.localStorage.getItem("activeCustomer");
                    const customer = storageCustomer != null ? storageCustomer! : metadata.customer;

                    config.headers.Authorization = `Bearer ${token}`;
                    config.baseURL = `${process.env.REACT_APP_API_URL}/${customer}`;

                    console.log('config changed', config)
                }

                return config;
            });


        // workaround: wait 200ms before rendering content
        if (!isLoading && justLoaded) {
            setTimeout(() => { setJustLoaded(false) }, 200);
        }

        return () => instance.interceptors.response.eject(interceptor);

    }, [isLoading]);


    return (!isLoading && !justLoaded) ? children:  <LayoutSplashScreen />;
}


export default instance;
export { AxiosInterceptor }