import ApexCharts, { ApexOptions } from "apexcharts";
import { FC, useEffect, useRef } from "react"
import { getCSS, getCSSVariableValue } from "../../../_metronic/assets/ts/_utils";

type Props = {
    "data": any,
    "title": string
}

const TopicsMap: FC<Props> = ({data, title}) => {
    const chartRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!chartRef.current) {
            return
        }

        const height = parseInt(getCSS(chartRef.current, 'height'))

        const chart = new ApexCharts(chartRef.current, getChartOptions(height, data))
        if (chart) {
            chart.render()
        }

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef])

    return (

        <div
            className='card h-500px'
        >
            <div className='card-header border-0 pt-9'>
            <div className='card-title m-0 flex-column align-items-start'>
                    <span className='card-label fw-bolder fs-3 mb-0'>{title}</span>
                    <span className="text-gray-400 mb-2 fw-semibold fs-7">Baseado em todas as avaliações coletadas</span>
                </div>
            </div>

            <div className='card-body p-9'>
                <div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '350px' }} />
            </div>
        </div>
    )
}

export default TopicsMap

function getChartOptions(height: number, data: any): ApexOptions {
    // const labelColor = getCSSVariableValue('--bs-gray-500')
    // const borderColor = getCSSVariableValue('--bs-gray-200')
    // const baseColor = getCSSVariableValue('--bs-primary')
    // const secondaryColor = getCSSVariableValue('--bs-gray-300')

    return {
        series: [
            {
                data: data[0].facet.topicsFacet.buckets.map((item:any) => {
                    return {
                        x: `${item._id} (${item.count})`,
                        y: item.count
                    }
                })
            },
        ],
        chart: {
            fontFamily: 'inherit',
            type: 'treemap',
            height: height,
            toolbar: {
                show: false,
            }
        },
        tooltip: {
            enabled: false
        },
        colors: [
            "#DC239B",
            "#FF5BC5",
            "#FF83D3",
            "#FF97DA",
            "#FFBFE9"
        ]
        // legend: {
        //     show: true,
        // },
        // dataLabels: {
        //     enabled: false,
        // },
        // stroke: {
        //     show: true,
        //     width: 2
        // }
    }
}
