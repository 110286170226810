import clsx from "clsx";
import React, { FC, useEffect, useState } from "react";
import { KTSVG } from "../../../helpers";
import { HeaderUserMenu } from "../../../partials";
import { useLayout } from "../../core";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

const toolbarButtonMarginClass = "ms-1 ms-lg-3",
  toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px";

const Topbar: FC = () => {
  const { config } = useLayout();

  const { user } = useAuth0();

  const [customer, setCustomer] = useState("");

  const history = useHistory();

  useEffect(() => {
    if (user) {
      const metadata =
        user["https://reviews-api.konfidency.com.br/app_metadata"];

      const storageCustomer = window.localStorage.getItem("activeCustomer");
      const customer =
        storageCustomer != null ? storageCustomer! : metadata.customer;
      setCustomer(customer);
    }
  }, []);

  useEffect(() => {
    if (user && customer) {
      (window as any).intercomSettings = {
        app_id: "b5yhlscr",
        name: user?.name,
        email: user?.email,
        company: {
          company_id: customer,
          name: customer,
        },
      };
      try {
        (window as any).loadIntercom();
      } catch (err) {
        console.log("error setting customer on intercom chat");
      }
    }
  }, [user, customer]);

  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div> */}
      {/* Activities */}
      <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
      >
        {/* begin::Drawer toggle */}
        {/* <div
          className={clsx('btn btn-icon btn-active-light-primary', toolbarButtonHeightClass)}
          id='kt_activities_toggle'
        >
          <KTSVG
            path='/media/icons/duotone/Media/Equalizer.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div> */}
        {/* end::Drawer toggle */}
      </div>
      {/* Quick links */}
      <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
      >
        {/* begin::Menu wrapper */}
        {/* <div
          className={clsx('btn btn-icon btn-active-light-primary', toolbarButtonHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotone/Layout/Layout-4-blocks.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div> */}
        {/* <QuickLinks /> */}
        {/* end::Menu wrapper */}
      </div>

      {/* CHAT */}
      <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
      >
        {/* begin::Menu wrapper */}
        {/* <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative',
            toolbarButtonHeightClass
          )}
          id='kt_drawer_chat_toggle'
        >
          <KTSVG
            path='/media/icons/duotone/Communication/Group-chat.svg'
            className={toolbarButtonIconSizeClass}
          />

          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
        </div> */}
        {/* end::Menu wrapper */}
      </div>

      {/* NOTIFICATIONS */}
      <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
      >
        {/* begin::Menu- wrapper */}
        {/* <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotone/Code/Compiling.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div> */}
        {/* <HeaderNotificationsMenu /> */}
        {/* end::Menu wrapper */}
      </div>
      {user &&
        user["https://reviews-api.konfidency.com.br/app_metadata"] &&
        user["https://reviews-api.konfidency.com.br/app_metadata"].customers &&
        Array.isArray(
          user["https://reviews-api.konfidency.com.br/app_metadata"].customers
        ) && (
          <div className="d-flex align-items-center">
            <select
              className="form-select form-select-white h-50px"
              value={customer}
              onChange={(e) => {
                window.localStorage.setItem("activeCustomer", e.target.value);
                history.go(0);
              }}
            >
              {user[
                "https://reviews-api.konfidency.com.br/app_metadata"
              ].customers.map((customer: string, index: number) => {
                return (
                  <option key={index} value={customer}>
                    {customer}
                  </option>
                );
              })}
            </select>
          </div>
        )}
      {/* begin::User */}
      <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
        id="kt_header_user_menu_toggle"
      >
        {/* begin::Toggle */}
        <div
          className={clsx(
            "cursor-pointer symbol",
            toolbarUserAvatarHeightClass
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          {user && <img src={user.picture} alt=" " />}
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === "menu" && (
        <div
          className="d-flex align-items-center d-lg-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
            id="kt_header_menu_mobile_toggle"
          >
            <KTSVG
              path="/media/icons/duotone/Text/Toggle-Right.svg"
              className="svg-icon-1"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { Topbar };
