import { FC, useEffect, useRef } from "react"
import TotalReviews from "./TotalReviews"

type Props = {
    data: any,
    totalReviews: number
}

const RatingDistributionItem: FC<Props> = ({data, totalReviews}) => {

    return (

        <div className="mb-5">
            <div className="row mb-2">
                <div className='rating col-md-8'>
                    <div className={`rating-label me-2 ${data._id > 0 ? 'checked' : ''}`}>
                        <i className='bi bi-star-fill fs-1'></i>
                    </div>
                    <div className={`rating-label me-2  ${data._id > 1 ? 'checked' : ''}`}>
                        <i className='bi bi-star-fill fs-1'></i>
                    </div>
                    <div className={`rating-label me-2  ${data._id > 2 ? 'checked' : ''}`}>
                        <i className='bi bi-star-fill fs-1'></i>
                    </div>
                    <div className={`rating-label me-2  ${data._id > 3 ? 'checked' : ''}`}>
                        <i className='bi bi-star-fill fs-1'></i>
                    </div>
                    <div className={`rating-label me-2  ${data._id > 4 ? 'checked' : ''}`}>
                        <i className='bi bi-star-fill fs-1'></i>
                    </div>
                </div>
            <div className="col-md-4">
                <div className="fs-2 fw-bolder text-end">{(data.total/totalReviews*100).toFixed(2)}%</div>
                <div className="fs-8 text-muted text-end">{data.total} avaliações</div>
            </div>
        </div>

        <div className="bg-secondary h-8px rounded">
        <div
            className={`bg-primary rounded h-8px`}
            role='progressbar'
            style={{width: `${(data.total/totalReviews*100).toFixed(2)}%`}}
          ></div>
          </div>
        </div>
    )
}

export default RatingDistributionItem
