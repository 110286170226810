import ApexCharts, { ApexOptions } from "apexcharts";
import { FC, useEffect, useRef } from "react"
import { getCSS, getCSSVariableValue } from "../../../_metronic/assets/ts/_utils";
import RatingDistributionItem from "./RatingDistributionItem";

type Props = {
    count: number,
    total: number
}

const ProductsOverview: FC<Props> = ({count, total}) => {

    return (

        <div className='card h-200px'>
            <div className='card-header border-0 pt-9'>
                <div className='card-title m-0 flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-0'>Produtos avaliados</span>
                    <span className="text-gray-400 mb-2 fw-semibold fs-7">Avaliações publicadas</span>
                </div>
            </div>
            <div className='card-body p-9 pt-1'>
                <div className="fs-3hx fw-bolder text-center">{count}</div>
                <div className="bg-secondary h-8px rounded">
                    <div
                        className={`bg-primary rounded h-8px`}
                        role='progressbar'
                        style={{ width: `${count < total ? count/total*100 : 100}%` }}
                    ></div>
                </div>
                <div className="fs-7 text-muted text-end mt-3">de {total} produtos</div>
            </div>
        </div>
    )
}

export default ProductsOverview
