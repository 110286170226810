/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import { useAuth0} from '@auth0/auth0-react';
import { LayoutSplashScreen } from '../../_metronic/layout/core'


const Routes: FC = () => {

  const { isAuthenticated, isLoading, buildAuthorizeUrl } = useAuth0();

  // const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  return (
    <Switch>
      
      {!isAuthenticated && !isLoading ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route
      render={() => {
        buildAuthorizeUrl().then((url) => {
          window.location.replace(url);
        });
        
        return null;
      }}
    />
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from='/auth' to='/' />
      )}

      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />

      {isAuthenticated && (
        <MasterLayout>
          <PrivateRoutes />
        </MasterLayout>
      )}

      {isLoading && (
        <Route>
          <LayoutSplashScreen />
        </Route>
      )}
    </Switch>
  )
}

export {Routes}
