/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { Button } from 'react-bootstrap-v5';
import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios';
import { Link } from 'react-router-dom';

const HeaderUserMenu: FC = () => {

  const { user, logout, getAccessTokenSilently } = useAuth0();

  const changePassword = async () => {
    const token = await getAccessTokenSilently({
      audience: `https://reviews-api.konfidency.com.br`,
      scope: "read:current_user app_metadata",
    });

    if(user) {
      const result = await axios.post(`${process.env.REACT_APP_API_URL}/user/change-password-request`, {}, { headers: { Authorization: `Bearer ${token}`}});
      window.location.href = result.data.ticket;
    }
  }

  

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-400px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='' src={user?.picture} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user?.name}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {/* {user.email} */}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item'>
        <a href="javascript:;" className='menu-link px-5' onClick={changePassword}>
          Alterar senha
        </a>
      </div>
      {/* <div className='separator my-2'></div>
      <div className='menu-item'>
        <Link className='menu-link px-5' to="/users">
          Gerenciar usuários
        </Link>
      </div> */}


      <div className='separator my-2'></div>


        <Button onClick={() => { window.localStorage.removeItem("activeCustomer"); logout({returnTo: window.location.origin })}} variant="link" className='menu-item menu-link px-5'>
          Sair
        </Button>
    </div>
  )
}

export {HeaderUserMenu}
