import ApexCharts, { ApexOptions } from "apexcharts";
import { FC, useEffect, useRef } from "react"
import { getCSS, getCSSVariableValue } from "../../../_metronic/assets/ts/_utils";

type Props = {
    "data": any,
    title: string,
    showOpens: boolean
}

const NotificationStats: FC<Props> = ({data, title, showOpens}) => {
    const chartRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!chartRef.current) {
            return
        }

        const height = parseInt(getCSS(chartRef.current, 'height'))

        const chart = new ApexCharts(chartRef.current, getChartOptions(height, data, showOpens))
        if (chart) {
            chart.render()
        }

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef])

    return (

        <div
            className='card h-500px'
        >
            <div className='card-header border-0 pt-9'>
                <div className='card-title m-0'>
                    <span className='card-label fw-bolder fs-3 mb-1'>Notificações por semana - {title}</span>
                </div>
            </div>

            <div className='card-body p-9'>
                <div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '350px' }} />
            </div>
        </div>
    )
}

export default NotificationStats

function getChartOptions(height: number, data: any, showOpens: boolean): ApexOptions {
    // const labelColor = getCSSVariableValue('--bs-gray-500')
    // const borderColor = getCSSVariableValue('--bs-gray-200')
    // const baseColor = getCSSVariableValue('--bs-primary')
    // const secondaryColor = getCSSVariableValue('--bs-gray-300')

    const series = (showOpens) ? [
        {
            name: 'Enviadas',
            data: data.map((item:any) => item.total),
        },
        {
            name: 'Abertas',
            data: data.map((item:any) => item.open),
        },
        {
            name: 'Respondidas',
            data: data.map((item:any) => item.converted),
        },
    ] : [
        {
            name: 'Enviadas',
            data: data.map((item:any) => item.total),
        },
        {
            name: 'Respondidas',
            data: data.map((item:any) => item.converted),
        },
    ]

    return {
        series: series,
        chart: {
            fontFamily: 'inherit',
            type: 'area',
            // stacked: true,
            // stackType: '100%',
            height: height,
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '30%',
                borderRadius: 5,
            },
        },
        legend: {
            show: true,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2
        },
        xaxis: {
            categories: data.map((item:any) => {
                return item.weekYear;
            }),
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    // colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    // colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        fill: {
            opacity: 1,
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return val + ' notificações'
                },
            },
        },
        //   colors: [baseColor, secondaryColor],
        grid: {
            // borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
    }
}
