/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from "@auth0/auth0-react";
import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { FallbackView } from "../../_metronic/partials";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { MenuTestPage } from "../pages/MenuTestPage";

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(
    () => import("../pages/layout-builder/BuilderPageWrapper")
  );
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  const ReviewsPage = lazy(() => import("../pages/reviews/ReviewsPage"));
  const NpsPage = lazy(() => import("../pages/nps/NpsPage"));
  const CustomersPage = lazy(() => import("../pages/customers/CustomersPage"));
  const CacheManagerPage = lazy(() => import("../pages/admin/CacheManagerPage"));
  const StoreReviewsPage = lazy(
    () => import("../pages/store-reviews/StoreReviewsPage")
  );
  const QuestionsPage = lazy(() => import("../pages/questions/QuestionsPage"));
  const ProductsPage = lazy(() => import("../pages/products/ProductsPage"));
  const ReviewsSettingsPage = lazy(
    () => import("../pages/reviews/SettingsPage")
  );
  const UsersPage = lazy(() => import("../pages/users/UsersPage"));
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
  const NotificationsPage = lazy(
    () => import("../pages/notifications/NotificationsPage")
  );
  const EmbeddedSignup = lazy(
    () => import("../pages/notifications/EmbeddedSignup")
  );

  const { /*isAuthenticated,*/ user } = useAuth0();

  function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  const returnUrl = query.get("returnUrl");
  const redirectUrl = returnUrl ? decodeURIComponent(returnUrl) : "/dashboard";

  useEffect(() => {

    if (user) {
      const metadata =
        user["https://reviews-api.konfidency.com.br/app_metadata"];
      try {
        (window as any).gtag("set", { customer: metadata.customer });
      } catch (err) {}
    }
  }, []);

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path="/dashboard" component={DashboardWrapper} />
        <Route path="/builder" component={BuilderPageWrapper} />
        <Route path="/review-settings" component={ReviewsSettingsPage} />
        <Route path="/reviews" component={ReviewsPage} />
        <Route path="/nps" component={NpsPage} />
        <Route path="/customers" component={CustomersPage} />
        <Route path="/cache-manager" component={CacheManagerPage} />
        <Route path="/store-reviews" component={StoreReviewsPage} />
        <Route path="/questions" component={QuestionsPage} />
        <Route path="/products" component={ProductsPage} />
        <Route path="/users" component={UsersPage} />
        <Route path="/notifications" component={NotificationsPage} />
        <Route path="/whatsapp-signup" component={EmbeddedSignup} />

        <Route path="/crafted/pages/profile" component={ProfilePage} />
        <Route path="/crafted/pages/wizards" component={WizardsPage} />
        <Route path="/crafted/widgets" component={WidgetsPage} />
        <Route path="/crafted/account" component={AccountPage} />
        <Route path="/apps/chat" component={ChatPage} />
        <Route path="/menu-test" component={MenuTestPage} />
        <Redirect from="/auth" to="/dashboard" />
        <Redirect exact from="/" to={redirectUrl} />
        <Redirect to="error/404" />
      </Switch>
    </Suspense>
  );
}
