import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { Routes } from './routing/Routes'
import { Auth0Provider } from '@auth0/auth0-react'
import { AxiosInterceptor } from "../setup/axios/AxiosInterceptor";


type Props = {
  basename: string
}

const App: React.FC<Props> = ({ basename }) => {


  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            {/* <AuthInit> */}
            <Auth0Provider
              domain="konfidency.us.auth0.com"
              clientId="gNlboCgjC7I8rg4ofHmazO4C3jCTmE0M"
              audience="https://reviews-api.konfidency.com.br"
              scope="read:current_user update:current_user_metadata app_metadata"
              redirectUri={window.location.origin + "?returnUrl=" + encodeURIComponent(window.location.pathname + window.location.search)}
            >
              <AxiosInterceptor>
                <Routes />
              </AxiosInterceptor>
            </Auth0Provider>
            {/* </AuthInit> */}
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export { App }
